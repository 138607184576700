<template>
  <div class="fullscreen" v-show="status">
    <div class="d-flex justify-content-center align-items-center h-100">
        <div class="text-center">
            <b-spinner style="width: 3rem; height: 3rem;" class="text-primary" label="Large Spinner" type="grow"></b-spinner>
            <p class="text-center mt-1">Adatok betöltése</p>
        </div>
    </div>
  </div>
</template>

<script>

import {
  BSpinner,
} from 'bootstrap-vue'

export default {
  components: {
    BSpinner,
  },
  props: {
    status: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
    .fullscreen{
        width: 100%;
        height: 100%;
        z-index: 9999;
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
    }
</style>
