<template>
  <b-card-code title="">
    <div class="row mb-2">
      <div class="col-lg-6">
        <h4>Feldolgozatlan számlák</h4>
      </div>
      <div class="col-lg-6 text-right">
        <button class="btn btn-primary mr-0 mr-md-2" @click="$router.push('/invoice/list/all')">Összes számla</button>
        <button class="btn btn-primary mr-0 mr-md-2" @click="$router.push('/invoice/list/project')">Projekthez rendelt számlák</button>
        <button class="btn btn-primary mr-0 mr-md-2" @click="$router.push('/invoice/list/archive')">Archivált számlák</button>
      </div>
    </div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'invoice_price'"
          class="text-nowrap"
        >
          <span>{{ parseInt(props.row.invoice_price).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }}</span>
        </div>

        <div
          v-else-if="props.column.field === 'invoice_number'"
          class="text-nowrap"
        >
          <span style="cursor:pointer" :title="props.row.long_name">{{props.row.invoice_number}}</span>
        </div>

        <!-- Column: Status -->
        <!--<span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span>-->

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <!--<b-dropdown-item v-b-modal.changeStatusModal @click="changeStatus(props.row.id)">
                <feather-icon
                  icon="CheckCircleIcon"
                  class="mr-50"
                />
                <span>Státusz állítás</span>
              </b-dropdown-item>-->
              <!--<b-dropdown-item @click="show(props.row.id)">
                <feather-icon
                  icon="EyeIcon"
                  class="mr-50"
                />
                <span>Feladatok</span>
              </b-dropdown-item>-->
              <!--<b-dropdown-item @click="document(props.row.id)">
                <feather-icon
                  icon="FileTextIcon"
                  class="mr-50"
                />
                <span>Dokumentum generálás</span>
              </b-dropdown-item>-->
              <b-dropdown-item v-if="props.row.have_project" v-b-modal.setProject @click="$router.push('/project/list/invoice/'+props.row.project_id)">
                <feather-icon
                  icon="FolderIcon"
                  class="mr-50"
                />
                <span>Tovább a projekt oldalra</span>
              </b-dropdown-item>
              <b-dropdown-item v-b-modal.setProject @click="selected = props.row.id">
                <feather-icon
                  icon="PlusCircleIcon"
                  class="mr-50"
                />
                <span>Projekthez rendelés</span>
              </b-dropdown-item>
              <b-dropdown-item @click="arachive(props.row.id)">
                <feather-icon
                  icon="FolderIcon"
                  class="mr-50"
                />
                <span>Archíválás</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Mutasd 1-től
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['25','50','100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap ">-ig a {{ props.total }} elemet </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <b-modal id="setProject" hide-footer title="Számla projekthez rendelése">
      <b-form-group
        label="Munka azonosító"
        label-for="work_number"
      >
        <b-form-select
          id="work_number"
          v-model="project_id"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="work_numberOptions"
        />
      </b-form-group>
      <p class="text-right">
        <button class="btn btn-primary" @click="addToProject">Hozzáadás</button>
      </p>
    </b-modal>
    <Loading :status="status" />
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BPagination, BFormSelect, BDropdown, BDropdownItem, BFormGroup,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Loading from '@/components/loading/Loading.vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import axios from 'axios'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    Loading,
  },
  data() {
    return {
      status: false,
      pageLength: 25,
      dir: false,
      project_id: '',
      columns: [
        {
          label: 'Számla azonosító',
          field: 'invoice_number',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés névre',
          },
        },
        {
          label: 'Megrendelő',
          field: 'customer',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés megrendelők között',
          },
        },
        {
          label: 'Teljesítés kelte',
          field: 'fulfilment_date',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés teljesítés dátumára',
          },
        },
        {
          label: 'Kiállítás dátuma',
          field: 'created_in_rs3',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés kiállítás dátumára',
          },
        },
        {
          label: 'Fizetési határidő',
          field: 'invoice_date',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés fizetési határidő dátumára',
          },
        },
        {
          label: 'Végösszeg nettó',
          field: 'invoice_price',
          tdClass: 'text-right',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés összegre',
          },
        },
        {
          label: 'Pénznem',
          field: 'invoice_price_currency',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés pénznem vezetőre',
          },
        },
        {
          label: '',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      selected: null,
      work_numberOptions: [],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        Folyamatban: 'light-info',
        Késésben: 'light-warning',
        Lejárt: 'light-danger',
        Kész: 'light-success',
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.status = true
    this.getProjects()
    axios({
      method: 'get',
      
      url: 'invoice/project/options',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }).then(res => {
      res.data.forEach(element => {
        if (element.archive != 1) {
          this.work_numberOptions.push({
            value: element.id,
            text: element.name,
          })
        }
        this.status = false
      })
    })
  },
  methods: {
    addToProject() {
      axios({
        method: 'post',
        
        url: `invoice/add/project/${this.selected}`,
        data: {
          project_id: this.project_id,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.getProjects()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sikeresen hozzárendelve',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.$root.$emit('bv::hide::modal', 'setProject')
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Minden mező kitöltése kötelező',
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      })
    },
    arachive(id) {
      axios({
        method: 'put',
        
        url: `invoice/change/status/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.getProjects()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sikeresen archiválva',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      })
    },
    editProject(id) {
      this.$router.push({ name: 'monolit.projects.edit', params: { id } })
    },
    getProjects() {
      this.status = true
      axios({
        method: 'get',
        
        url: 'invoice/list',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(response => {
        this.rows = response.data
        this.status = false
      })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
